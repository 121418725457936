<template>
  <div>
    <a-skeleton :paragraph="{ rows: 4 }" v-if="loading" />
    <div class="card" v-else>
      <div class="card-header pt-3 pb-3">
        <div class="d-flex align-items-center justify-content-between">
          <div
            class="d-flex align-items-center"
            :class="{ 'order-1 flex-row-reverse': $i18n.locale === 'ar-AR' }"
          >
            <a-badge
              :count="filteredNotifications.length"
              :class="$i18n.locale === 'ar-AR' ? 'ml-2' : 'mr-2'"
              :number-style="{
                backgroundColor: 'transparent',
                color: '#999',
                boxShadow: '0 0 0 1px #d9d9d9 inset',
              }"
              :overflowCount="999"
            />
            <h5 class="mb-0">{{ $t("topBar.notification.notifications") }}</h5>
          </div>
          <div
            class="d-flex align-items-center"
            :class="{ 'order-0 flex-row-reverse': $i18n.locale === 'ar-AR' }"
          >
            <div
              :style="{
                width: '350px',
                [$i18n.locale === 'ar-AR' ? 'margin-left' : 'margin-right']:
                  '10px',
              }"
            >
              <a-input-search
                v-model="searchQuery"
                :placeholder="$t('topBar.notification.searchPlaceholder')"
                @change="handleSearch"
              />
            </div>
            <a-button @click="toggleSort">
              <a-icon
                :type="sortAscending ? 'sort-ascending' : 'sort-descending'"
              />
            </a-button>
          </div>
        </div>
      </div>
      <div class="p-5">
        <template v-if="filteredNotifications.length > 0">
          <div
            class="card"
            v-for="(item, index) in paginatedNotifications"
            :key="index"
            @click="handleNotificationClick(item)"
            :class="{ 'cursor-pointer': getNotificationLink(item) !== '/' }"
          >
            <div class="card-content p-4">
              <div class="d-flex align-items-center mb-3 justify-content-end">
                <div class="d-flex flex-column text-right">
                  <div
                    class="d-flex align-items-center justify-content-end mb-1"
                  >
                    <h5 class="mb-0">{{ item.topic }}</h5>
                  </div>
                  <span class="text-muted" style="font-size: 13px">
                    {{ moment(item.createdAt).format("HH:mm DD/MM/YYYY") }}
                  </span>
                </div>
                <a-avatar
                  size="small"
                  icon="bell"
                  class="ml-3"
                  style="background-color: #969696"
                />
              </div>
              <p class="mb-0 text-right" dir="rtl">{{ item.message }}</p>
            </div>
          </div>
          <div class="text-center mt-4">
            <a-pagination
              v-model="currentPage"
              :total="total"
              :pageSize="pageSize"
              @change="handlePageChange"
            />
          </div>
        </template>
        <template v-else>
          <div class="text-center p-4">
            <a-empty :description="$t('topBar.notification.noNotifications')" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import moment from "moment";

export default {
  data() {
    return {
      loading: false,
      searchQuery: "",
      sortAscending: false,
      notifications: [],
      currentPage: 1,
      pageSize: 10,
    };
  },

  computed: {
    filteredNotifications() {
      let filtered = [...this.notifications];

      // Apply search filter if query exists
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        filtered = filtered.filter(
          (item) =>
            (item.topic && item.topic.toLowerCase().includes(query)) ||
            (item.message && item.message.toLowerCase().includes(query))
        );
      }
      // Apply sorting
      filtered.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return this.sortAscending ? dateA - dateB : dateB - dateA;
      });

      return filtered;
    },
    paginatedNotifications() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredNotifications.slice(start, end);
    },
    total() {
      return this.filteredNotifications.length;
    },
  },

  created() {
    this.fetchNotifications();
    this.markAllAsRead();
  },

  methods: {
    moment,
    async fetchNotifications() {
      this.loading = true;
      try {
        const response = await apiClient.get("/notifications");
        this.notifications = response.data.notifications.map((item) => ({
          ...item,
          link: this.getNotificationLink(item),
        }));
        this.sortAscending = false;
      } catch (error) {
        this.$message.error(this.$t("topBar.notification.errorFetch"));
      }
      this.loading = false;
    },
    getNotificationLink(notif) {
      switch (notif.refType) {
        case "studentNameChange":
          return "/eleve/" + notif.ref;
        case "notifyManagerNewComment":
          return "/actuality";
        case "notifyManagerNewLike":
          return "/actuality";
        default:
          return "/";
      }
    },
    handleNotificationClick(notification) {
      if (notification.link !== "/" && this.$route.path !== notification.link) {
        this.$router.push(notification.link);
      }
    },
    async markAllAsRead() {
      try {
        await apiClient.post("/notifications/mark-as-read", {});
        // Emit an event to update the notification count in the topbar
        this.$root.$emit("notifications-read");
      } catch (error) {
        console.error("Failed to mark notifications as read:", error);
      }
    },
    handleSearch() {
      this.currentPage = 1;
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    toggleSort() {
      this.sortAscending = !this.sortAscending;
    },
  },
};
</script>

<style scoped>
.text-right {
  text-align: right;
}

[dir="rtl"] {
  direction: rtl;
  unicode-bidi: embed;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
